var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"section-shaped my-0 skew-separator skew-mini "},[_c('v-img',{staticClass:"page-header header-filter  header-small ",attrs:{"max-height":"390px","src":"/media/test-biz-header.png","gradient":"to top right, rgba(0,0,0,.53), rgba(25,32,72,.7)"}})],1),_c('div',{staticClass:"main main-raised",staticStyle:{"background":"#fff"}},[_c('div',{staticClass:"section"},[_c('div',{staticClass:"container"},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('md-card',{staticClass:"bg-success"},[_c('md-card-content',[_c('h5',{staticClass:"card-category card-category-social text-white"},[_c('i',{staticClass:"far fa-newspaper"}),_vm._v(" Your B2B Orders ")]),_c('p',{staticClass:"card-description text-white"},[_vm._v(" Send a message to one of your partners to invite them to Goose. Once enrolled they will automatically be connected with no application needed. ")]),_c('div',{staticClass:"card-stats text-center"})])],1)],1),_c('v-col',[_c('v-row',[_c('br')]),_c('v-row',[_c('div',{staticClass:"headline"},[_vm._v("Search by date range :")])]),_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Initial Date:","prepend-icon":"mdi-calendar","readonly":""},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menu2),callback:function ($$v) {_vm.menu2=$$v},expression:"menu2"}},[_c('v-date-picker',{model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}})],1)],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Final Date","prepend-icon":"mdi-calendar","readonly":""},model:{value:(_vm.endDate),callback:function ($$v) {_vm.endDate=$$v},expression:"endDate"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.datePicker),callback:function ($$v) {_vm.datePicker=$$v},expression:"datePicker"}},[_c('v-date-picker',{attrs:{"min":_vm.initialDate},on:{"input":_vm.searchByDate},model:{value:(_vm.endDate),callback:function ($$v) {_vm.endDate=$$v},expression:"endDate"}})],1)],1)],1)],1)],1),_c('br'),_c('div',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.requestHeader,"items":_vm.ordersWithRequests,"sort-by":"name"},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_vm._v("Orders With Requests")]),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),_c('v-spacer')],1)]},proxy:true},{key:"item",fn:function(ref){
var item = ref.item;
return [_c('tr',[_c('td',[_vm._v(" "+_vm._s(item.identifier)+" ")]),_c('td',[_vm._v(" "+_vm._s(_vm._f("date_format")(item.created_at))+" ")]),_c('td',[_vm._v(" "+_vm._s(item.masterOrder.buyerCompany.company_name)+" ")]),_c('td',[_vm._v(" "+_vm._s(_vm._f("fullName")(item.masterOrder.buyerUser.LastName))+" ")]),_c('td',[_vm._v(" "+_vm._s(_vm._f("dollar_format")(item.total))+" ")]),_c('td',[_c('span',[_vm._v(" "+_vm._s(item.cancelRequests.length > 0 ? 'cancel' : 'return')+" ")])]),_c('td',[_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.$router.push('/market/order-detail/' + item.identifier)}}},[_vm._v(" mdi-eye ")])],1)])]}}])}),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.orders,"sort-by":['created_at'],"sort-desc":[true, false]},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_vm._v("Orders")]),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":""},on:{"keyup":_vm.onSearch},model:{value:(_vm.searchInput),callback:function ($$v) {_vm.searchInput=$$v},expression:"searchInput"}}),_c('v-spacer')],1)]},proxy:true},{key:"item",fn:function(ref){
var item = ref.item;
return [_c('tr',[_c('td',[_vm._v(" "+_vm._s(item.identifier)+" ")]),_c('td',[_vm._v(" "+_vm._s(_vm._f("date_format")(item.created_at))+" ")]),_c('td',[_vm._v(" "+_vm._s(item.masterOrder.buyerCompany.company_name)+" ")]),_c('td',[_vm._v(" "+_vm._s(_vm._f("fullName")(item.masterOrder.buyerUser.LastName))+" ")]),_c('td',[_vm._v(" "+_vm._s(_vm._f("dollar_format")(item.total))+" ")]),_c('td',[_c('span',[_vm._v(" "+_vm._s(_vm._f("currentStatus")(item.historicalStatus))+" ")])]),_c('td',[_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.$router.push('/market/order-detail/' + item.identifier)}}},[_vm._v(" mdi-eye ")])],1)])]}}])})],1)],1)])])])}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <div>
    <div class="section-shaped my-0 skew-separator skew-mini ">
      <v-img
        class="page-header header-filter  header-small "
        max-height="390px"
        src="/media/test-biz-header.png"
        gradient="to top right, rgba(0,0,0,.53), rgba(25,32,72,.7)"
      >
      </v-img>
    </div>

    <div class="main main-raised" style="background:#fff;">
      <div class="section">
        <div class="container">
          <v-row>
            <v-col class="" cols="12" sm="6">
              <md-card class="bg-success">
                <md-card-content>
                  <h5 class="card-category card-category-social text-white">
                    <i class="far fa-newspaper" /> Your B2B Orders
                  </h5>

                  <p class="card-description text-white">
                    Send a message to one of your partners to invite them to
                    Goose. Once enrolled they will automatically be connected
                    with no application needed.
                  </p>

                  <div class="card-stats text-center"></div>
                </md-card-content>
              </md-card>
            </v-col>

            <v-col>
              <v-row><br /></v-row>
              <v-row><div class="headline">Search by date range :</div></v-row>
              <v-row>
                <v-col cols="12" sm="6"
                  ><v-menu
                    v-model="menu2"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template #activator="{ on, attrs }">
                      <v-text-field
                        v-model="date"
                        label="Initial Date:"
                        prepend-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker v-model="date"></v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="12" sm="6"
                  ><v-menu
                    v-model="datePicker"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template #activator="{ on, attrs }">
                      <v-text-field
                        v-model="endDate"
                        label="Final Date"
                        prepend-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="endDate"
                      :min="initialDate"
                      @input="searchByDate"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <br />
          <div>
            <v-data-table
              :headers="requestHeader"
              :items="ordersWithRequests"
              sort-by="name"
              class="elevation-1"
            >
              <template #top>
                <v-toolbar flat>
                  <v-toolbar-title>Orders With Requests</v-toolbar-title>
                  <v-divider class="mx-4" inset vertical></v-divider>
                  <v-spacer></v-spacer>
                </v-toolbar>
              </template>
              <template #item="{ item }">
                <tr>
                  <td>
                    {{ item.identifier }}
                  </td>
                  <td>
                    {{ item.created_at | date_format }}
                  </td>
                  <td>
                    {{ item.masterOrder.buyerCompany.company_name }}
                  </td>
                  <td>
                    {{ item.masterOrder.buyerUser.LastName | fullName }}
                  </td>
                  <td>
                    {{ item.total | dollar_format }}
                  </td>
                  <td>
                    <span>
                      {{ item.cancelRequests.length > 0 ? 'cancel' : 'return' }}
                    </span>
                  </td>
                  <td>
                    <v-icon
                      small
                      class="mr-2"
                      @click="
                        $router.push('/market/order-detail/' + item.identifier)
                      "
                    >
                      mdi-eye
                    </v-icon>
                  </td>
                </tr>
              </template>
            </v-data-table>
            <v-data-table
              :headers="headers"
              :items="orders"
              :sort-by="['created_at']"
              :sort-desc="[true, false]"
              class="elevation-1"
            >
              <template #top>
                <v-toolbar flat>
                  <v-toolbar-title>Orders</v-toolbar-title>
                  <v-divider class="mx-4" inset vertical></v-divider>
                  <v-text-field
                    v-model="searchInput"
                    append-icon="mdi-magnify"
                    label="Search"
                    single-line
                    hide-details
                    @keyup="onSearch"
                  />
                  <v-spacer></v-spacer>
                </v-toolbar>
              </template>
              <template #item="{ item }">
                <tr>
                  <td>
                    {{ item.identifier }}
                  </td>
                  <td>
                    {{ item.created_at | date_format }}
                  </td>
                  <td>
                    {{ item.masterOrder.buyerCompany.company_name }}
                  </td>
                  <td>
                    {{ item.masterOrder.buyerUser.LastName | fullName }}
                  </td>
                  <td>
                    {{ item.total | dollar_format }}
                  </td>
                  <td>
                    <span> {{ item.historicalStatus | currentStatus }} </span>
                  </td>
                  <td>
                    <v-icon
                      small
                      class="mr-2"
                      @click="
                        $router.push('/market/order-detail/' + item.identifier)
                      "
                    >
                      mdi-eye
                    </v-icon>
                  </td>
                </tr>
              </template>
            </v-data-table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import GetVendorOrders from '@/graphql/queries/GetVendorOrders.gql'

export default {
  filters: {
    currentStatus(historicalStatus) {
      var sorted = historicalStatus.map((status) => ({ ...status }))
      sorted.sort((a, b) => {
        new Date(a.date) - new Date(b.date)
      })
      return sorted.length > 0 ? sorted[sorted.length - 1].status : ''
    },
    date_format: function(value) {
      if (value) {
        return moment(String(value)).format('MM/DD/YYYY')
      }
    },
    dollar_format: function(value) {
      const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 2,
      })
      return formatter.format(value)
    },
    fullName(user) {
      return (user.FirstName || '') + ' ' + (user.LastName || '')
    },
  },
  data: () => ({
    error: '',
    menu2: false,
    datePicker: false,
    endDate: null,
    date: null,
    searchInput: null,
    dialog: false,
    headers: [
      { text: 'Order ID' },
      { text: 'Date', value: 'created_at' },
      { text: 'Buyer Name' },
      { text: 'User' },
      { text: 'Total $' },
      { text: 'Status' },
      { text: 'Actions' },
    ],
    requestHeader: [
      { text: 'Order ID' },
      { text: 'Date' },
      { text: 'Buyer Name' },
      { text: 'User' },
      { text: 'Total $' },
      { text: 'Request' },
      { text: 'Actions' },
    ],
    orders: [],
    editedIndex: -1,
    editedItem: {
      id: '',
      name: 0,
      user: 0,
      date: 0,
      total: 0,
      status: 0,
    },
    defaultItem: {
      id: '',
      name: 0,
      user: 0,
      date: 0,
      total: 0,
      status: 0,
    },
  }),
  apollo: {
    orders: {
      query: GetVendorOrders,
      fetchPolicy: 'no-cache',
      variables() {
        return { vendor: this.$store.state.auth.user.company_relation }
      },
      skip() {
        return !this.$store.state.auth.user.company_relation
      },
    },
  },
  computed: {
    ordersWithRequests() {
      return this.orders.filter(
        (o) => o.cancelRequests.length > 0 || o.returnRequests.length > 0
      )
    },
    initialDate() {
      return new Date(this.date).toISOString()
    },
  },
  methods: {
    async searchByDate() {
      await this.$apollo.queries.orders.refetch({
        searchInput: this.searchInput,
        date: new Date(this.date).toISOString(),
        nextDay: new Date(this.endDate).toISOString(),
      })
      // const m = new Date(this.endDate).toISOString()
      // console.log(m)
      // this.menu2 = false
      // var tomorrow = new Date(this.date)
      // tomorrow.setDate(tomorrow.getDate() + 1)
      // const nextDay = new Date(tomorrow).toISOString()
    },
    async onSearch() {
      await this.$apollo.queries.orders.refetch({
        searchInput: this.searchInput,
        date: undefined,
        nextDay: undefined,
      })
    },
    async approveCancel() {
      // let res = await this.$apollo.mutate({
      //   client: "ecomClient",
      //   mutation: gql`
      //     mutation cancelVendorOrderMutation($order_id: Int!) {
      //       cancelVendorOrder(order_id: $order_id)
      //     }
      //   `,
      //   variables: {
      //     order_id: id,
      //   },
      // });
      // if (res.data.cancelVendorOrder == true) {
      //   Swal.fire({
      //     title: "",
      //     text: "Order cancelled.",
      //     icon: "success",
      //     confirmButtonClass: "btn btn-secondary",
      //   });
      //   this.$apollo.queries.orders.refetch();
      // }
    },
    async denyCancel() {
      // let res = await this.$apollo.mutate({
      //   client: "ecomClient",
      //   mutation: gql`
      //     mutation denyCancelVendorOrderMutation($order_id: Int!) {
      //       denyCancelVendorOrder(order_id: $order_id)
      //     }
      //   `,
      //   variables: {
      //     order_id: id,
      //   },
      // });
      // if (res.data.denyCancelVendorOrder == true) {
      //   Swal.fire({
      //     title: "",
      //     text: "Request Denied.",
      //     icon: "success",
      //     confirmButtonClass: "btn btn-secondary",
      //   });
      //   this.$apollo.queries.orders.refetch();
      // }
    },
  },
}
</script>
